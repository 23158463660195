import { SET_STEPS, INCREMENT_CURRENT_STEP, DECREMENT_CURRENT_STEP } from "../types";

const steps = [
    {
        key: 0,
        id: 'Paso 1',
        name: 'Contacto',
        href: '#',
        status: 'current',
        title: 'Contacta con nosotros',
        text: 'Una vez iniciado el contacto, nuestros ingenieros e ingenieras escucharán lo que deseas, posteriormente te harán llegar una liga con un documento en línea donde harán una serie de preguntas que debes responder para generar tu propuesta.'
    },
    {
        key: 1,
        id: 'Paso 2',
        name: 'Análisis de información',
        href: '#', status: 'upcoming',
        title: 'Con base al cuestionario que respondiste',
        text: 'Una vez analizada la información compartida por ti, creamos un prototipo propuesta para que determinemos el camino a seguir.'
    },
    {
        key: 2,
        id: 'Paso 3',
        name: 'Establecemos metas',
        href: '#',
        status: 'upcoming',
        title: '¿Cuando requieres tu app?',
        text: 'Siempre entregamos en tiempo y forma nuestros trabajos, por lo qué, el proyecto se entrega en el tiempo establecido.'
    },
    {
        key: 3,
        id: 'Paso 4',
        name: 'Adelante',
        href: '#',
        status: 'upcoming',
        title: 'Te compartimos todo tu material',
        text: 'Una vez liberado el proyecto, cuenta con que tu App estará lista y optimizada para la utilización de tus clientes.'
    }
]

export const defaultStepperState = {
    steps: steps,
    currentStep: 0
};

export const AppsReducer = (state, action) => {
    const { currentStep, steps } = state;
    const { type, payload } = action;
    switch (type) {
        case SET_STEPS:
            return {
                ...state,
                steps: payload.steps
            };
        case INCREMENT_CURRENT_STEP:
            if(currentStep < steps.length - 1){
                var log = currentStep + 1;
                steps.filter(item => item.key === log).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === log - 1).forEach(element => {
                    element.status = 'complete'
                });
                return {
                    ...state,
                    currentStep: currentStep + 1
                }
            }
            else {
                console.log("aqui")
                return {
                    currentStep
                }
            }
        case DECREMENT_CURRENT_STEP:
            console.log(currentStep);
            if (currentStep > 0) {
                var logDecrement = currentStep - 1;
                steps.filter(item => item.key === logDecrement).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === logDecrement + 1).forEach(element => {
                    element.status = 'upcoming'
                });
                return {
                    ...state,
                    currentStep: currentStep - 1
                }
            }
            else {
                return {
                    currentStep
                }
            }
        default:
            return state;
    }
};

export default AppsReducer;
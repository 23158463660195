import { Helmet } from "react-helmet";
import {
    SparklesIcon,
    CodeIcon,
    ChartPieIcon,
    ShieldCheckIcon,
    PresentationChartBarIcon,
    DotsHorizontalIcon
} from '@heroicons/react/outline'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

const metrics = [
    { id: 1, icon: ChartPieIcon, emphasis: 'Sistema de analíticos', rest: 'podrás ver en tiempo real, cuantos visitantes, de donde, entre otros indicadores.' },
    { id: 2, icon: ShieldCheckIcon, emphasis: 'Seguridad de la información', rest: 'estamos seguros que la información de tu negocio y tu cliente no es vulnerable.' },
    { id: 3, icon: PresentationChartBarIcon, emphasis: 'Hosting y dominio', rest: 'una vez que estemos listo para comenzar, nos encargamos no solo de la configuración, sino de la optimización.' },
    { id: 4, icon: DotsHorizontalIcon, emphasis: 'Soporte técnico', rest: 'siempre estamos ahí para cualquier eventualidad que no este contemplada.' },
]

export default function Inicio() {
    return (
        <>
            <Helmet>
                <title>Inicio ~ Asymmetrics</title>
                <meta name="description" content="Con gusto implementamos tu proyecto web, e-commerce, apps móviles, entre otros. ¡Siempre listos!" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="bg-white dark:bg-asymmetrics-600">
                <main>
                    {/* Hero section */}
                    <div className="relative topBar">
                        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100 dark:bg-asymmetrics-600" />
                        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                                <div className="absolute inset-0">
                                    <img
                                        className="h-full w-full object-cover"
                                        src="assets/img/back_asymmetrics1.jpg"
                                        alt="People working on laptops"
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-r from-asymmetrics-200 to-asymmetrics-100 mix-blend-multiply" />
                                </div>
                                <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                                    <h1 className="text-center text-4xl font-extrabold tracking-wider sm:text-5xl lg:text-6xl">
                                        <span className="block text-white">Digitaliza</span>
                                        <span className="block text-indigo-200">Actualiza</span>
                                    </h1>
                                    <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                                        Es momento de llevar tu negocio a la nueva tendencia dígital
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Alternating Feature Sections */}
                    <div className="relative pt-16 pb-32 overflow-hidden">
                        <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100 dark:from-asymmetrics-600" />
                        <div className="relative">
                            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                                    <div>
                                        <div className="mt-6">
                                            <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl">
                                                <span className="block bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-asymmetrics-400 bg-clip-text text-transparent">
                                                    Convertimos todas esas líneas en lo que tus clientes quieren ver
                                                </span>
                                            </h2>
                                            <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                                                A lo largo de los años, nosotros como ingenieros e ingenieras, nos hemos capacitado día con día para poder transmitir y traducir todas estas líneas de código, que nos encontramos escribiendo ahora mismo, para presentar a nuestros, y tus clientes, esa imagen que desean ver.<br />
                                                Recuerda que siempre estamos listos para iniciar con tu proyecto.
                                            </p>
                                            <div className="mt-6">
                                                <span className="inline-flex bg-gradient-to-r from-asymmetrics-600 to-asymmetrics-600 dark:from-asymmetrics-400 dark:to-asymmetrics-500 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-yellow-secondary-700 hover:to-yellow-secondary-700">
                                                    <CodeIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-12 sm:mt-16 lg:mt-0">
                                    <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                        <img
                                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                            src="assets/img/back_asymmetrics2.png"
                                            alt="Inbox user interface"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-24">
                            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                                <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                                    <div>
                                        <div className="mt-6">
                                            <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl">
                                                <span className="block bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-asymmetrics-400 bg-clip-text text-transparent">
                                                    Sabemos la importancia de mantenernos actualizados
                                                </span>
                                            </h2>
                                            <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
                                                Es importante analizar las tendencias de diseño antes de proponerte un desarrollo, en caso de que ya cuentes con uno, lo adaptamos para que se muestre de la mejor manera.
                                            </p>
                                            <div className="mt-6">
                                                <span className="inline-flex bg-gradient-to-r from-asymmetrics-600 to-asymmetrics-600 dark:from-asymmetrics-400 dark:to-asymmetrics-500 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-yellow-secondary-700 hover:to-yellow-secondary-700">
                                                    <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                                    <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                        <img
                                            className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                            src="assets/img/back_asymmetrics3.png"
                                            alt="Customer profile user interface"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Gradient Feature Section */}
                    <div>
                        <div className="max-w-3xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-12 lg:px-8">
                            <div className="mt-4 flex justify-center">
                                <img className="mt-4 w-1/4 hidden dark:block" src="assets/img/LogoAsymmetricsFullLight.png" alt="Logo Asymmetrics" />
                                <img className="mt-4 w-1/4 dark:hidden" src="assets/img/LogoAsymmetricsFull.png" alt="Logo Asymmetrics Light" />
                            </div>
                            <div className="bg-white dark:bg-asymmetrics-600">
                                <div className="flex min-h-full">
                                    <div className="self-center justify-center w-full container mx-auto">
                                        <div className="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                            <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-center pb-10 w-100">
                                                <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-asymmetrics-400 bg-clip-text text-transparent">
                                                    Nuestros clientes más recientes
                                                </span>
                                            </h2>
                                            <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 text-center">
                                                Agradecemos la confianza y el apoyo, siempre listos para seguir brindando soluciones con la mejor calidad de servicio.
                                            </p>
                                            <div className="mt-6 grid grid-cols-1 gap-0.5 md:grid-cols-3 lg:mt-8">
                                                <div className="col-span-1 flex justify-center py-10 px-10 bg-gray-50 dark:bg-asymmetrics-700">
                                                    <div className="self-center">
                                                        <img
                                                            className="h-16 dark:hidden"
                                                            src="assets/img/logos/ClientesLogoBruckLight.png"
                                                            alt="Logo Brück Germany"
                                                        />
                                                        <img
                                                            className="h-16 hidden dark:block"
                                                            src="assets/img/logos/ClientesLogoBruckDark.png"
                                                            alt="Logo Brück Germany"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-1 flex justify-center py-10 px-10 bg-gray-50 dark:bg-asymmetrics-700">
                                                    <img
                                                        className="dark:hidden"
                                                        src="assets/img/logos/ClientesLogoBriomantLight.png"
                                                        alt="Logo Briomant" />
                                                    <img
                                                        className="hidden dark:block"
                                                        src="assets/img/logos/ClientesLogoBriomantDark.png"
                                                        alt="Logo Briomant"
                                                    />
                                                </div>
                                                <div className="col-span-1 flex justify-center py-10 px-10 bg-gray-50 dark:bg-asymmetrics-700">
                                                    <img
                                                        className="dark:hidden"
                                                        src="assets/img/logos/ClientesLogoVanessaCamposLight.png"
                                                        alt="Logo Vanessa Campos" />
                                                    <img
                                                        className="hidden dark:block"
                                                        src="assets/img/logos/ClientesLogoVanessaCamposDark.png"
                                                        alt="Logo Vanessa Campos"
                                                    />
                                                </div>
                                                <div className="col-span-1 flex justify-center py-10 px-10 bg-gray-50 dark:bg-asymmetrics-700">
                                                    <img
                                                        className="dark:hidden"
                                                        src="assets/img/logos/ClientesLogoIntexLight.png"
                                                        alt="Logo IntexTrading" />
                                                    <img
                                                        className="hidden dark:block"
                                                        src="assets/img/logos/ClientesLogoIntexDark.png"
                                                        alt="Logo IntexTrading"
                                                    />
                                                </div>
                                                <div className="col-span-1 flex justify-center py-10 px-10 bg-gray-50 dark:bg-asymmetrics-700">
                                                    <img
                                                        src="assets/img/logos/LogoYachtsAndFishin.png"
                                                        alt="Logo Yachts and Fishin"
                                                    />
                                                </div>
                                                <div className="col-span-1 flex justify-center py-10 px-10 bg-gray-50 dark:bg-asymmetrics-700">
                                                    <img
                                                        className="dark:hidden"
                                                        src="assets/img/logos/ClientesLogoOxiPueLight.png"
                                                        alt="Logo OxiPue"
                                                    />
                                                    <img
                                                        className="hidden dark:block"
                                                        src="assets/img/logos/ClientesLogoOxiPueDark.png"
                                                        alt="Logo OxiPue"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Stats section */}
                    <div className="relative bg-gray-900">
                        <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
                            <div className="h-full w-full xl:grid xl:grid-cols-2">
                                <div className="h-full xl:relative xl:col-start-2">
                                    <img
                                        className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                                        src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                                        alt="People working on laptops"
                                    />
                                    <div
                                        aria-hidden="true"
                                        className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
                            <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
                                <h2 className="text-sm font-semibold tracking-wide uppercase">
                                    <span className="bg-gradient-to-r from-yellow-secondary-300 to-yellow-secondary-500 bg-clip-text text-transparent">
                                        Valor agregado
                                    </span>
                                </h2>
                                <p className="mt-3 text-3xl font-extrabold text-white">
                                    Hay bastantes acciones
                                </p>
                                <p className="mt-5 text-lg text-gray-300">
                                    Todos los servicios, cuentan con acciones que tomamos en cuenta como prioridad, es importante que sepas que todos los desarrollos cuentan con funciones que no son visibles ni para ti ni para tus clientes, sin embargo allí se encuentran.
                                </p>
                                <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                                    {metrics.map((item) => (
                                        <div key={item.id}>
                                            <span className="mb-4 inline-flex bg-gradient-to-r from-asymmetrics-600 to-asymmetrics-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-yellow-secondary-700 hover:to-yellow-secondary-700">
                                                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </span>
                                            <p>
                                                <span className="mt-1 block text-base text-gray-300">
                                                    <span className="font-medium text-white">{item.emphasis}</span> {item.rest}
                                                </span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CTA Section */}
                    <div className="bg-white dark:bg-asymmetrics-600">
                        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
                            <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 dark:text-white sm:text-4xl">
                                <span className="block">¿Tienes comentarios?</span>
                                <span className="block bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-asymmetrics-200 bg-clip-text text-transparent">
                                    Estamos listos para comenzar, solo un clic más
                                </span>
                            </h2>
                            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
                                <a
                                    href="https://wa.me/5212227718704?text=Me%20interesa%20Asymmetrics"
                                    className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-whatsapp-600 to-whatsapp-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-whatsapp-700 hover:to-whatsapp-700"
                                >
                                    <WhatsAppIcon className="mr-2" /> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

import { useState } from "react";
import { Helmet } from "react-helmet";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ErrorIcon from '@material-ui/icons/Error';
import TextArea from '../components/TextArea'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const ContactoSchema = Yup.object().shape({
    Nombre: Yup.string().required('Por favor, indicanos tu nombre'),
    Apellidos: Yup.string().required('Por favor, indicanos tus apellidos'),
    CorreoElectronico: Yup.string().email('Inserta un correo electrónico valido').required('Por favor, inserta tu correo electrónico'),
    Celular: Yup.number().typeError("Por favor, sólo inserta números").required('Por favor inserta tu número teléfonico'),
    Perfil: Yup.string().required('Por favor inserta tu perfil'),
    Comentarios: Yup.string().required('Por favor, indicanos tus comentarios').max(500),
    Enteraste: Yup.string().required('Por favor, indicanos cómo te enteraste de nosotros')
});

export default function Contact() {
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    return (
        <>
            <Helmet>
                <title>Contacto ~ Asymmetrics</title>
                <meta name="description" content="¡Nos encantaría saber de ti! Envíenos un mensaje utilizando el formulario o envíenos un correo electrónico." />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="topBar">
                <div className="relative bg-white dark:bg-asymmetrics-600">
                    <div className="lg:absolute lg:inset-0">
                        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                            <img
                                className="h-56 w-full object-cover lg:absolute lg:h-full"
                                src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
                        <div className="lg:pr-8">
                            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                                <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-left w-100">
                                    <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-asymmetrics-300 bg-clip-text text-transparent">
                                        Trabajemos juntos
                                    </span>
                                </h2>
                                <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 sm:mt-3">
                                    ¡Nos encantaría saber de ti! Envíenos un mensaje utilizando el formulario o envíenos un correo electrónico.
                                </p>
                                <Formik
                                    initialValues={{
                                        Nombre: '',
                                        Apellidos: '',
                                        CorreoElectronico: '',
                                        Celular: '',
                                        Perfil: '',
                                        Comentarios: '',
                                        Enteraste: '',
                                    }}
                                    validationSchema={ContactoSchema}
                                    onSubmit={values => {
                                        setLoading(true);
                                        axios.post('https://asymmetrics.com.mx:4001/api/lead', {
                                            Name: values.Nombre + " " + values.Apellidos,
                                            Mail: values.CorreoElectronico,
                                            Cel: values.Celular,
                                            Perfil: values.Perfil,
                                            Comments: values.Comentarios,
                                            Find: values.Enteraste,
                                            Date: Date.now()
                                        })
                                            .then(function (response) {
                                                if (response.status === 200) {
                                                    values.Nombre = '';
                                                    values.Apellidos = '';
                                                    values.CorreoElectronico = '';
                                                    values.Celular = '';
                                                    values.Comentarios = '';
                                                    values.Enteraste = '';
                                                    setOpenAlert(true);
                                                    setLoading(false);
                                                }
                                                else {
                                                    console.log(response.status);
                                                }
                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                            });
                                    }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className="mt-4">
                                                <label htmlFor="Nombre" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                    Nombre(s)
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="Nombre"
                                                        id="Nombre"
                                                        autoComplete="given-name"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-asymmetrics-500 focus:border-asymmetrics-500 border-gray-300 rounded-md"
                                                    />
                                                    {errors.Nombre && touched.Nombre ? (
                                                        <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Nombre}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <label htmlFor="Apellidos" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                    Apellidos
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="Apellidos"
                                                        id="Apellidos"
                                                        autoComplete="family-name"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-asymmetrics-500 focus:border-asymmetrics-500 border-gray-300 rounded-md"
                                                    />
                                                    {errors.Apellidos && touched.Apellidos ? (
                                                        <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Apellidos}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <label htmlFor="CorreoElectronico" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                    Correo electrónico
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        id="CorreoElectronico"
                                                        name="CorreoElectronico"
                                                        type="email"
                                                        autoComplete="email"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-asymmetrics-500 focus:border-asymmetrics-500 border-gray-300 rounded-md"
                                                    />
                                                    {errors.CorreoElectronico && touched.CorreoElectronico ? (
                                                        <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.CorreoElectronico}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <div className="flex justify-between">
                                                    <label htmlFor="Celular" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                        Celular
                                                    </label>
                                                    <span id="Celular" className="text-sm text-gray-500 dark:text-gray-300">
                                                        Max. 10 números
                                                    </span>
                                                </div>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="Celular"
                                                        id="Celular"
                                                        autoComplete="tel"
                                                        aria-describedby="phone-description"
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-asymmetrics-500 focus:border-asymmetrics-500 border-gray-300 rounded-md"
                                                    />
                                                    {errors.Celular && touched.Celular ? (
                                                        <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Celular}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <div className="flex justify-between">
                                                    <label htmlFor="Perfil" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                        Perfil
                                                    </label>
                                                </div>
                                                <div className="mt-1">
                                                    <Field name="Perfil" as="select" className="block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                                                        <option>Selecciona una opción</option>
                                                        <option value="Freelancer">Freelancer</option>
                                                        <option value="Startup">Startup</option>
                                                        <option value="Enterprise">Enterprise</option>
                                                    </Field>
                                                    {errors.Perfil && touched.Perfil ? (
                                                        <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Perfil}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <div className="flex justify-between">
                                                    <label htmlFor="how-can-we-help" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                        ¿Tienes algunda duda o comentario?
                                                    </label>
                                                    <span id="Comentarios" className="text-sm text-gray-500 dark:text-gray-300">
                                                        Max. 500 caracteres
                                                    </span>
                                                </div>
                                                <div className="mt-1">
                                                    <Field
                                                        as={TextArea}
                                                        id="Comentarios"
                                                        name="Comentarios"
                                                        aria-describedby="Comentarios"
                                                        rows={4}
                                                        className="block w-full shadow-sm sm:text-sm focus:ring-asymmetrics-500 focus:border-asymmetrics-500 border border-gray-300 rounded-md"
                                                    />
                                                    {errors.Comentarios && touched.Comentarios ? (
                                                        <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Comentarios}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <label htmlFor="Enteraste" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                    ¿Cómo te enteraste de nosotros?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="Enteraste"
                                                        id="Enteraste"
                                                        className="shadow-sm focus:ring-asymmetrics-500 focus:border-asymmetrics-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                    {errors.Enteraste && touched.Enteraste ? (
                                                        <div className="py-2 px-2 bg-red-500 rounded-md shadow mt-2 text-white text-sm"><ErrorIcon fontSize="small" /> {errors.Enteraste}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="text-right sm:col-span-2 mt-4">
                                                {
                                                    loading ?
                                                        <button className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-asymmetrics-600 dark:bg-asymmetrics-300 text-base font-medium text-white hover:bg-orange-antigua-700 dark:hover:bg-asymmetrics-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                                                            <CircularProgress size={20} className="mx-4 text-white" />
                                                            Enviando...
                                                        </button>
                                                        :
                                                        <button
                                                            type="submit"
                                                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-asymmetrics-600 dark:bg-asymmetrics-300 hover:bg-asymmetrics-700 dark:hover:bg-asymmetrics-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                                        >
                                                            Enviar
                                                        </button>
                                                }
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Tus datos se han enviado con exito.
                </Alert>
            </Snackbar>
        </>
    );
}

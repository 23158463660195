import { useReducer, useCallback } from "react";
import { SMMReducer, defaultStepperState } from "./SMMReducer";
import SMMContext from "./SMMContext"

const SMMState = (props) => {
    
    const [state, dispatch] = useReducer(SMMReducer, defaultStepperState)

    const incrementCurrentStep = useCallback(() => {
        dispatch({
            type: 'INCREMENT_CURRENT_STEP'
        });
    }, [dispatch]);

    const decrementCurrentStep = useCallback(() => {
        dispatch({
            type: 'DECREMENT_CURRENT_STEP'
        });
    }, [dispatch]);

    const setSteps = useCallback(steps => dispatch({ type: 'SET_STEPS', payload: { steps } }), [dispatch]);

    return (
        <SMMContext.Provider value={{
            steps: state.steps,
            selectedStep: state.selectedStep,
            currentStep: state.currentStep,
            incrementCurrentStep,
            decrementCurrentStep,
            setSteps
        }}>
            {props.children}
        </SMMContext.Provider>
    )
}

export default SMMState;
import { SET_STEPS, INCREMENT_CURRENT_STEP, DECREMENT_CURRENT_STEP } from "../types";

const steps = [
    {
        key: 0,
        id: 'Paso 1',
        name: 'Contacto',
        href: '#',
        status: 'current',
        title: 'Contacta con nosotros',
        text: 'A través de una breve llamada de unos 20 minutos, cuéntanos cuál es tu proyecto o que tienes en mente y con base en ello te proponemos la solución que mejor se adapte a tu negocio. Sugerimos tener en mente tiempo máximo de desarrollo, funcionalidades especificas en caso de que existan, número de pestañas y si cuentan con imagen para tu tienda.'
    },
    {
        key: 1,
        id: 'Paso 2',
        name: 'Compartenos tu información',
        href: '#', status: 'upcoming',
        title: 'Envíanos tu información',
        text: 'Creamos un espacio en la nube para que nos compartas toda la información disponible para el diseño de tu tienda, realizamos un analísis y con base en eso te propones la solución de e-commerce índicada para ti. Es importante establecer métodos de trabajo y canales de comunicación.'
    },
    {
        key: 2,
        id: 'Paso 3',
        name: 'Establecemos metas',
        href: '#',
        status: 'upcoming',
        title: '¿Cuando requieres que este en línea?',
        text: 'El tiempo medio que utilizamos para construir tu e-commerce es de 3 a 4 semanas, por lo que, si se adapta a tu meta, con gusto iniciamos, si necesitas menos tiempo ¡también lo logramos!'
    },
    {
        key: 3,
        id: 'Paso 4',
        name: 'Adelante',
        href: '#',
        status: 'upcoming',
        title: 'Ya establecimos metas y conceptos',
        text: 'Si te parece bien, nos reunimos una vez a la semana para realizar avances y recibir retroalimentación por tu parte, no liberamos el sitio web hasta que estes 100% satisfecho.'
    }
]

export const defaultStepperState = {
    steps: steps,
    currentStep: 0
};

export const EcommerceReducer = (state, action) => {
    const { currentStep, steps } = state;
    const { type, payload } = action;
    switch (type) {
        case SET_STEPS:
            return {
                ...state,
                steps: payload.steps
            };
        case INCREMENT_CURRENT_STEP:
            if(currentStep < steps.length - 1){
                var log = currentStep + 1;
                steps.filter(item => item.key === log).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === log - 1).forEach(element => {
                    element.status = 'complete'
                });
                return {
                    ...state,
                    currentStep: currentStep + 1
                }
            }
            else {
                console.log("aqui")
                return {
                    currentStep
                }
            }
        case DECREMENT_CURRENT_STEP:
            console.log(currentStep);
            if (currentStep > 0) {
                var logDecrement = currentStep - 1;
                steps.filter(item => item.key === logDecrement).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === logDecrement + 1).forEach(element => {
                    element.status = 'upcoming'
                });
                return {
                    ...state,
                    currentStep: currentStep - 1
                }
            }
            else {
                return {
                    currentStep
                }
            }
        default:
            return state;
    }
};

export default EcommerceReducer;
import { SET_STEPS, INCREMENT_CURRENT_STEP, DECREMENT_CURRENT_STEP } from "../types";

const steps = [
    {
        key: 0,
        id: 'Paso 1',
        name: 'Contacto',
        href: '#',
        status: 'current',
        title: 'Contacta con nosotros',
        text: 'A través de una breve llamada de unos 20 minutos, cuéntanos cuál es tu proyecto o que tienes en mente y con base en ello te proponemos la solución que mejor se adapte a tu negocio. Sugerimos tener en mente tiempo máximo de desarrollo, funcionalidades especificas en caso de que existan, si cuentas con algún avance, y todos los recursos que tengas a la mano.'
    },
    {
        key: 1,
        id: 'Paso 2',
        name: 'Creamos el espacio en la nube',
        href: '#', status: 'upcoming',
        title: 'Te compartimos el espacio',
        text: 'Creamos un espacio en la nube para que nos compartas toda la información disponible para la implementación del CMS que mejor se adapte a ti, realizamos un analísis y con base en ello, te proponemos la solución. Es importante establecer métodos de trabajo y canales de comunicación.'
    },
    {
        key: 2,
        id: 'Paso 3',
        name: 'Establecemos metas',
        href: '#',
        status: 'upcoming',
        title: 'Proyectamos tiempo de desarrollo',
        text: 'Según la dimensión y alcance del proyecto, te proponemos el tiempo de desarrollo, te compartimos un calendario de entrega de avances y modificaciones y nos adaptamos a tu meta.'
    },
    {
        key: 3,
        id: 'Paso 4',
        name: 'Adelante',
        href: '#',
        status: 'upcoming',
        title: 'Ya establecimos metas y conceptos',
        text: 'Estaremos contigo hasta que tu personal se encuentre capacitado para la utilización administración del CMS implementando.'
    }
]

export const defaultStepperState = {
    steps: steps,
    currentStep: 0
};

export const CMSReducer = (state, action) => {
    const { currentStep, steps } = state;
    const { type, payload } = action;
    switch (type) {
        case SET_STEPS:
            return {
                ...state,
                steps: payload.steps
            };
        case INCREMENT_CURRENT_STEP:
            if(currentStep < steps.length - 1){
                var log = currentStep + 1;
                steps.filter(item => item.key === log).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === log - 1).forEach(element => {
                    element.status = 'complete'
                });
                return {
                    ...state,
                    currentStep: currentStep + 1
                }
            }
            else {
                console.log("aqui")
                return {
                    currentStep
                }
            }
        case DECREMENT_CURRENT_STEP:
            if (currentStep > 0) {
                var logDecrement = currentStep - 1;
                steps.filter(item => item.key === logDecrement).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === logDecrement + 1).forEach(element => {
                    element.status = 'upcoming'
                });
                return {
                    ...state,
                    currentStep: currentStep - 1
                }
            }
            else {
                return {
                    currentStep
                }
            }
        default:
            return state;
    }
};

export default CMSReducer;
import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition, Switch } from '@headlessui/react'
import {
    TerminalIcon,
    DesktopComputerIcon,
    MenuIcon,
    XIcon,
    CheckCircleIcon,
    PhoneIcon,
    CodeIcon,
    CreditCardIcon,
    DeviceMobileIcon,
    ChatAltIcon,
    CursorClickIcon,
    SpeakerphoneIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import {
    Link
} from "react-router-dom";

const servicios = [
    {
        name: 'Desarrollo web',
        description: 'Crea o actualiza tu sitio web, es momento.',
        href: '/web',
        icon: CodeIcon,
    },
    {
        name: 'Diseño',
        description: 'Conocemos la importancia de tu imagen dígital, nosotros innovamos.',
        href: '/design',
        icon: DesktopComputerIcon,
    },
    {
        name: 'Sistemas',
        description: "Es momento de automatizar tus procedimientos.",
        href: '/systems',
        icon: TerminalIcon
    },
    {
        name: 'E-commerce',
        description: "La tendencia de hoy en día, es momento que vendas en línea",
        href: '/ecommerce',
        icon: CreditCardIcon,
    },
    {
        name: 'Apps móviles',
        description: "Generalmente una impresión de poder para tu negocio.",
        href: '/apps',
        icon: DeviceMobileIcon,
    },
    {
        name: 'Landing page',
        description: "Conecta rápido con tus clientes.",
        href: '/landingpage',
        icon: ChatAltIcon,
    },
    {
        name: 'CMS',
        description: "Herramientas efectiva para iniciar a corto plazo.",
        href: '/cms',
        icon: CursorClickIcon,
    },
    {
        name: 'Social media manager',
        description: "Es necesario que la sociedad te conozca.",
        href: '/SMM',
        icon: SpeakerphoneIcon,
    },
]

const callsToAction = [
    { name: 'Contacto', href: '#', icon: CheckCircleIcon },
    { name: 'Llamar', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const [enabled, setEnabled] = useState(localStorage.theme)

    useEffect(() => {
        if (localStorage.theme === 'dark') {
            setEnabled(true);
        }
        else {
            setEnabled(false);
        }
    }, [enabled])

    if (enabled) {
        localStorage.theme = 'dark'
        document.documentElement.classList.add('dark')
    }
    else {
        localStorage.removeItem('theme')
        document.documentElement.classList.remove('dark')
    }

    return (
        <>
            <header>
                <Popover className="bg-white dark:bg-asymmetrics-700 shadow-lg fixed w-full z-10">
                    <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <Link to="/">
                                <span className="sr-only">Workflow</span>
                                <img
                                    className="h-8 w-auto lg:h-16"
                                    src="assets/img/isoLight.png"
                                    alt="Isotipo Asymmetrics"
                                />
                            </Link>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button className="bg-white dark:bg-asymmetrics-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-asymmetrics-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                        <Popover.Group as="nav" className="hidden md:flex space-x-10">
                            <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-white dark:hover:text-asymmetrics-100">
                                Inicio
                            </Link>
                            <Popover>
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            className={classNames(
                                                open ? 'dark:text-white text-gray-500' : 'dark:text-gray-100 text-gray-500',
                                                'group dark:bg-asymmetrics-700 rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 dark:hover:text-asymmetrics-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500'
                                            )}
                                        >
                                            <span>Servicios</span>
                                            <ChevronDownIcon
                                                className={classNames(
                                                    open ? 'text-gray-600' : 'text-gray-400',
                                                    'ml-2 h-5 w-5 group-hover:text-gray-500'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 -translate-y-1"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-150"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 -translate-y-1"
                                        >
                                            <Popover.Panel className="hidden md:block absolute z-10 top-full inset-x-0 transform shadow-lg bg-white dark:bg-asymmetrics-600">
                                                <div className="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16 text-left">
                                                    {servicios.map((item) => (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-yellow-secondary-200 dark:hover:bg-asymmetrics-300"
                                                        >
                                                            <div className="flex md:h-full lg:flex-col">
                                                                <div className="flex-shrink-0">
                                                                    <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-asymmetrics-500 text-white sm:h-12 sm:w-12">
                                                                        <item.icon className="h-6 w-6" aria-hidden="true" />
                                                                    </span>
                                                                </div>
                                                                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                                                    <div>
                                                                        <p className="text-base font-medium text-asymmetrics-600 dark:text-white">{item.name}</p>
                                                                        <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">{item.description}</p>
                                                                    </div>
                                                                    <p className="mt-2 text-sm font-medium text-yellow-secondary-600 lg:mt-4">
                                                                        Ver servicio <span aria-hidden="true">&rarr;</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>
                                                <div className="bg-gray-50 dark:bg-asymmetrics-700">
                                                    <div className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                                                        {callsToAction.map((item) => (
                                                            <div key={item.name} className="flow-root">
                                                                <a
                                                                    href={item.href}
                                                                    className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 dark:hover:bg-asymmetrics-300"
                                                                >
                                                                    <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                                                    <span className="ml-3 dark:text-white">{item.name}</span>
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>

                            <Link to="/us" className="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-white dark:hover:text-asymmetrics-100">
                                Nosotros
                            </Link>
                            {/* <Link to="/customers" className="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-white dark:hover:text-asymmetrics-100">
                                Clientes
                            </Link> */}
                            <Link to="/contact" className="text-base font-medium text-gray-500 hover:text-gray-900 dark:text-white dark:hover:text-asymmetrics-100">
                                Contacto
                            </Link>
                            <Switch
                                checked={enabled}
                                onChange={setEnabled}
                                className={classNames(
                                    enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                )}
                            >
                                <span className="sr-only">Use setting</span>
                                <span
                                    className={classNames(
                                        enabled ? 'translate-x-5' : 'translate-x-0',
                                        'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                    )}
                                >
                                    <span
                                        className={classNames(
                                            enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                        )}
                                        aria-hidden="true"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    </span>
                                    <span
                                        className={classNames(
                                            enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                            'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                        )}
                                        aria-hidden="true"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                                        </svg>
                                    </span>
                                </span>
                            </Switch>
                        </Popover.Group>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            <a
                                href="https://wa.me/5212227718704?text=Me%20interesa%20Asymmetrics"
                                className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-whatsapp-600 to-whatsapp-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-whatsapp-700 hover:to-whatsapp-700"
                            >
                                <WhatsAppIcon className="mr-2" /> WhatsApp
                            </a>
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-asymmetrics-700 divide-y-2 divide-gray-50">
                                <div className="pt-5 pb-6 px-5 sm:pb-8">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <img
                                                className="h-10 w-auto"
                                                src="assets/img/isoLight.png"
                                                alt="Workflow"
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-white dark:bg-asymmetrics-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="mt-6 sm:mt-8">
                                        <nav>
                                            <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                                                {servicios.map((item) => (
                                                    <a
                                                        key={item.name}
                                                        href={item.href}
                                                        className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-asymmetrics-500"
                                                    >
                                                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-asymmetrics-500 text-white sm:h-12 sm:w-12">
                                                            <item.icon className="h-6 w-6" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-4 text-base font-medium text-gray-900 dark:text-white">{item.name}</div>
                                                    </a>
                                                ))}
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                <div className="py-6 px-5">
                                    <div className="grid grid-cols-2 gap-4">
                                        <Link to="/us" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700 dark:text-white">
                                            Nosotros
                                        </Link>

                                        <Link to="/customers" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700 dark:text-white">
                                            Clientes
                                        </Link>

                                        <Link to="/contact" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700 dark:text-white">
                                            Contacto
                                        </Link>
                                        <Switch
                                            checked={enabled}
                                            onChange={setEnabled}
                                            className={classNames(
                                                enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                            )}
                                        >
                                            <span className="sr-only">Use setting</span>
                                            <span
                                                className={classNames(
                                                    enabled ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                )}
                                            >
                                                <span
                                                    className={classNames(
                                                        enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                                                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                                                    </svg>
                                                </span>
                                                <span
                                                    className={classNames(
                                                        enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                                                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                                                    </svg>
                                                </span>
                                            </span>
                                        </Switch>
                                    </div>
                                    <div className="mt-6">
                                        <a
                                            href="/"
                                            className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                        >
                                            WhatsApp
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </header>
        </>
    );
}
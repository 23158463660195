import { Helmet } from "react-helmet";
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import { CheckIcon } from '@heroicons/react/outline'

//Context
import { useContext } from "react";
import SMMContext from './../context/SMM/SMMContext'

const profile = {
    name: 'Landing Page',
    email: 'contacto@asymmetrics.com.mx',
    avatar:
        'assets/img/smm/back_asymmetrics_smm_square.png',
    backgroundImage:
        'assets/img/smm/back_asymmetrics_smm.png'
}

const pricing = {
    tiers: [
        {
            title: 'Freelancer',
            price: '2,999',
            frequency: '/MXN',
            description: 'Pago mensual.',
            features: ['Una red social: Facebook, Twitter o Linkedin', 'Diseño y publicación de 2 posts por semana', '1 pauta publicitaria', 'Atención a usuarios e interacciones', 'Informe estadístico: Bimestral', 'Remarketing y Retargeting incluido', 'Sesión de fotos o video bimestral'],
            cta: '¡Lo quiero!',
            mostPopular: false,
            href:'https://wa.me/5212227718704?text=Me%20interesa%20el%20plan%20Freelancer%20para%20SMM'
        },
        {
            title: 'Startup',
            price: '5,999',
            frequency: '/MXN',
            description: 'Pago mensual.',
            features: [
                '2 Redes Sociales: Instagram, Tik Tok, Facebook, Twitter ó LinkedIn',
                'Diseño y publicación de 4 posts por semana',
                '2 pautas publicitarias',
                'Informe estadístico: Bimestral',
                'Atención a usuarios e interacciones',
                'Remarketing y Retargeting incluido',
                'Sesión de fotos o video mensual',
            ],
            cta: '¡Lo quiero!',
            mostPopular: true,
            href:'https://wa.me/5212227718704?text=Me%20interesa%20el%20plan%20Startup%20para%20SMM'
        },
        {
            title: 'Enterprise',
            price: '8,999',
            frequency: '/MXN',
            description: 'Pago mensual.',
            features: [
                '3 Redes Sociales: Instagram, Tik Tok, Facebook, Twitter ó LinkedIn',
                'Diseño y publicación de 6 posts por semana',
                '3 pautas publicitarias',
                'Informe estadístico: Mensual',
                'Atención a usuarios e interacciones',
                'Remarketing y Retargeting incluido',
                'Sesión de fotos o video mensual'
            ],
            cta: '¡Lo quiero!',
            mostPopular: false,
            href:'https://wa.me/5212227718704?text=Me%20interesa%20el%20plan%20Enterprise%20para%20SMM'
        },
    ],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const handleClickCorreo = () => {
    window.open("mailto:contato@asymmetrics.com.mx","_blank")
}

const handleClickWhats = () => {
    window.open("https://wa.me/5212227718704?text=Me%20interesa%20la%20adminitración%20de%20mis%20redes%20sociales","_blank")
}

export default function SMM() {
    const { steps, incrementCurrentStep, decrementCurrentStep, currentStep } = useContext(SMMContext)

    return (
        <>
            <Helmet>
                <title>Social Media Manager ~ Asymmetrics</title>
                <meta name="description" content="Nuestro equipo de ingenieros e ingenieras altamente capacitados estarán contigo mano a mano para el desarrollo de tu sitio web" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="topBar">
                <div>
                    <img className="h-32 w-full object-cover lg:h-48" src={profile.backgroundImage} alt={profile.name} />
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                        <div className="flex">
                            <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={profile.avatar} alt={profile.name} />
                        </div>
                        <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end text-left sm:space-x-6 sm:pb-1">
                            <div className="sm:hidden md:block mt-6 min-w-0 flex-1">
                                <h1 className="text-2xl font-bold text-gray-900 truncate">Social Media Manager</h1>
                            </div>
                            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                                <button
                                    onClick={handleClickCorreo}
                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >
                                    <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span>Correo electrónico</span>
                                </button>
                                <button
                                    onClick={handleClickWhats}
                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >
                                    <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span>WhatsApp</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
                        <h1 className="text-2xl font-bold text-gray-900 truncate">Social Media Manager</h1>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-12">
                    <div className="md:col-span-2 shadow-lg rounded-lg p-4 md:p-10">
                        <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-left w-100">
                            <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 bg-clip-text text-transparent">
                                Social Media Manager
                            </span>
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 text-left">
                            Contamos con las estrategías ideales para hacer crecer tus redes sociales, es necesario tener presencia en internet, por lo que estamos listos para ejercer acciones con base a analísis de tu mercado para establecer tu identidad.
                        </p>
                    </div>
                    <div className="shadow-lg rounded-lg p-4 md:p-10">
                        <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-left">
                            <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 bg-clip-text text-transparent">
                                ¿Quiénes lo hacen
                            </span>
                            ?
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 text-left">
                            Nuestro equipo de expertos y expertas en Social Media Manager, se encargaran de que llegues a la meta.
                        </p>
                    </div>
                </div>
                <div className="shadow-lg rounded-lg p-4 md:p-10 mt-10">
                    <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-left">
                        <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 bg-clip-text text-transparent">
                            ¿Cómo iniciar
                        </span>
                        ?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                        Nuestro equipo de ingenieros e ingenieras altamente capacitados estarán contigo mano a mano.
                    </p>
                    <div className="mt-12 text-left">
                        <nav aria-label="Progress">
                            <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                                {steps.length ? steps.map((step) => (
                                    <li key={step.name} className="md:flex-1">
                                        {step.status === 'complete' ? (
                                            <a
                                                href={step.href}
                                                className="group pl-4 py-2 flex flex-col border-l-4 border-yellow-secondary-600 hover:border-yellow-secondary-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                            >
                                                <span className="text-xs text-asymmetrics-600 font-semibold tracking-wide uppercase group-hover:text-asymmetrics-800">
                                                    {step.id}
                                                </span>
                                                <span className="text-sm font-medium">{step.name}</span>
                                            </a>
                                        ) : step.status === 'current' ? (
                                            <a
                                                href={step.href}
                                                className="pl-4 py-2 flex flex-col border-l-4 border-asymmetrics-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                                aria-current="step"
                                            >
                                                <span className="text-xs text-asymmetrics-600 font-semibold tracking-wide uppercase">{step.id}</span>
                                                <span className="text-sm font-medium">{step.name}</span>
                                            </a>
                                        ) : (
                                            <a
                                                href={step.href}
                                                className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                            >
                                                <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                                                    {step.id}
                                                </span>
                                                <span className="text-sm font-medium">{step.name}</span>
                                            </a>
                                        )}
                                    </li>
                                )) : null}
                            </ol>
                        </nav>
                        <div className="pt-10">
                            <div className="text-center md:text-right md:pt-10 pb-10">
                                {
                                    currentStep === 0 ?
                                        <button
                                            type="button"
                                            className="disabled ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                        >
                                            Anterior
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            onClick={decrementCurrentStep}
                                            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-600 hover:bg-asymmetrics-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                        >
                                            Anterior
                                        </button>
                                }
                                {
                                    currentStep > 2 ?
                                        <button
                                            type="button"
                                            className="disabled ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                        >
                                            Listo
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            onClick={incrementCurrentStep}
                                            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-600 hover:bg-asymmetrics-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                        >
                                            Siguiente
                                        </button>
                                }
                            </div>
                            {
                                steps.filter(item => item.key === currentStep).map((element) => (
                                    <div>
                                        <h1 className="text-2xl font-bold leading-tight text-asymmetrics-700">{element.title}</h1>
                                        <p className="mt-4 text-lg text-gray-500 text-left">
                                            {element.text}
                                        </p>
                                    </div>
                                )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10 mb-10">
                <div className="p-4 md:p-10 rounded-lg shadow-lg">
                    <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-left w-100">
                        <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 bg-clip-text text-transparent">
                            ¿Cuanto costará mi proyecto
                        </span>
                        ?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500 text-left">
                        El costo de un proyecto puede tornarse bastante relativo, sin embargo creamos unos planes para simplificar el proceso.
                    </p>

                    {/* Tiers */}
                    <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
                        {pricing.tiers.map((tier) => (
                            <div
                                key={tier.title}
                                className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
                            >
                                <div className="flex-1">
                                    <h3 className="text-xl font-medium bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 bg-clip-text text-transparent">{tier.title}</h3>
                                    {tier.mostPopular ? (
                                        <p className="absolute top-0 py-1.5 px-4 bg-asymmetrics-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                                            Más contratado
                                        </p>
                                    ) : null}
                                    <p className="mt-4 flex items-baseline text-gray-900">
                                        <span className="text-5xl font-medium bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 bg-clip-text text-transparent">${tier.price}</span>
                                        <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
                                    </p>
                                    <p className="mt-6 text-gray-500 text-left">{tier.description}</p>

                                    {/* Feature list */}
                                    <ul className="mt-6 space-y-6">
                                        {tier.features.map((feature) => (
                                            <li key={feature} className="flex">
                                                <CheckIcon className="flex-shrink-0 w-6 h-6 text-asymmetrics-500" aria-hidden="true" />
                                                <span className="ml-3 text-gray-500 text-left">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <a
                                    href={tier.href}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classNames(
                                        tier.mostPopular
                                            ? 'bg-asymmetrics-500 text-white hover:bg-asymmetrics-600'
                                            : 'bg-asymmetrics-50 text-white hover:bg-asymmetrics-100',
                                        'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
                                    )}
                                >
                                    {tier.cta}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
import { SET_STEPS, INCREMENT_CURRENT_STEP, DECREMENT_CURRENT_STEP } from "../types";

const steps = [
    {
        key: 0,
        id: 'Paso 1',
        name: 'Contacto',
        href: '#',
        status: 'current',
        title: 'Contacta con nosotros',
        text: 'A través de una llamada, nos informas el estatus actual de tus redes sociales, si es necesario inciar de cero o si comenzamos con algo ya existente.'
    },
    {
        key: 1,
        id: 'Paso 2',
        name: 'Compartenos tu información',
        href: '#', status: 'upcoming',
        title: 'Envíanos tu información',
        text: 'Todo el material gráfico que tengas disponible, por favor, compartelo con nostros para que iniciemos cuanto antes la maqueta de tu proximo objetivo.'
    },
    {
        key: 2,
        id: 'Paso 3',
        name: 'Establecemos metas',
        href: '#',
        status: 'upcoming',
        title: 'Proponemos metas mensuales para cada red social',
        text: 'Cada semana verificamos el estatus de la meta, si es necesario hacemos las modificaciones necesarias para logar cada uno de los objetivos mensuales.'
    },
    {
        key: 3,
        id: 'Paso 4',
        name: 'Adelante',
        href: '#',
        status: 'upcoming',
        title: 'Ya establecimos metas y conceptos',
        text: 'Trabajamos de la mano contigo para mantener la imagen y continuar generando tráfico en tus redes sociales, siempre estaremos pendientes del comportamiento de tu marca.'
    }
]

export const defaultStepperState = {
    steps: steps,
    currentStep: 0
};

export const SMMReducer = (state, action) => {
    const { currentStep, steps } = state;
    const { type, payload } = action;
    switch (type) {
        case SET_STEPS:
            return {
                ...state,
                steps: payload.steps
            };
        case INCREMENT_CURRENT_STEP:
            if(currentStep < steps.length - 1){
                var log = currentStep + 1;
                steps.filter(item => item.key === log).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === log - 1).forEach(element => {
                    element.status = 'complete'
                });
                return {
                    ...state,
                    currentStep: currentStep + 1
                }
            }
            else {
                console.log("aqui")
                return {
                    currentStep
                }
            }
        case DECREMENT_CURRENT_STEP:
            console.log(currentStep);
            if (currentStep > 0) {
                var logDecrement = currentStep - 1;
                steps.filter(item => item.key === logDecrement).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === logDecrement + 1).forEach(element => {
                    element.status = 'upcoming'
                });
                return {
                    ...state,
                    currentStep: currentStep - 1
                }
            }
            else {
                return {
                    currentStep
                }
            }
        default:
            return state;
    }
};

export default SMMReducer;
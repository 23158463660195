import { Helmet } from "react-helmet";

export default function Customers() {
    return (
        <>
            <Helmet>
                <title>Clientes ~ Asymmetrics</title>
                <meta name="description" content="Gracias por ser parte de la historia." />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="topBar">
                <div className="bg-white">
                    <div className="flex min-h-full">
                        <div className="self-center justify-center w-full container mx-auto">
                            <div className="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-center pb-10 w-100">
                                    <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 bg-clip-text text-transparent">
                                        Nuestros clientes
                                    </span>
                                </h2>
                                <p className="mt-4 text-lg text-gray-500 text-center">
                                    Agradecemos la confianza y el apoyo, siempre listos para seguir brindando soluciones con la mejor calidad de servicio.
                                </p>
                                <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img
                                            className="max-h-12"
                                            src="assets/img/logos/LogoBruck.png"
                                            alt="Logo Brück Germany"
                                        />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img 
                                            className="max-h-12" 
                                            src="assets/img/logos/LogoLightBriomant.png" 
                                            alt="Logo Briomant" />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img 
                                            className="max-h-12" 
                                            src="assets/img/logos/LogoVanessaCampos.png" 
                                            alt="Logo Vanessa Campos" />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img 
                                            className="max-h-12" 
                                            src="assets/img/logos/IntexLogoWeb.png" 
                                            alt="Logo IntexTrading" />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img
                                            className="max-h-14"
                                            src="assets/img/logos/LogoYachtsAndFishin.png"
                                            alt="Logo Yachts and Fishin"
                                        />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img
                                            className="max-h-10"
                                            src="assets/img/logos/OxiPue_Logotipo.png"
                                            alt="Logo OxiPue"
                                        />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img
                                            className="max-h-20"
                                            src="assets/img/logos/cycledistrict.jpg"
                                            alt="Cycle District"
                                        />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img className="max-h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage" />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img className="max-h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple" />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img className="max-h-12" src="https://tailwindui.com/img/logos/laravel-logo-gray-400.svg" alt="Laravel" />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img
                                            className="max-h-12"
                                            src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
                                            alt="StaticKit"
                                        />
                                    </div>
                                    <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                        <img
                                            className="max-h-12"
                                            src="https://tailwindui.com/img/logos/statamic-logo-gray-400.svg"
                                            alt="Statamic"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import { useReducer, useCallback } from "react";
import { EcommerceReducer, defaultStepperState } from "./EcommerceReducer";
import EcommerceContext from "./EcommerceContext"

const WebState = (props) => {
    
    const [state, dispatch] = useReducer(EcommerceReducer, defaultStepperState)

    console.log(state)

    const incrementCurrentStep = useCallback(() => {
        dispatch({
            type: 'INCREMENT_CURRENT_STEP'
        });
    }, [dispatch]);

    const decrementCurrentStep = useCallback(() => {
        dispatch({
            type: 'DECREMENT_CURRENT_STEP'
        });
    }, [dispatch]);

    const setSteps = useCallback(steps => dispatch({ type: 'SET_STEPS', payload: { steps } }), [dispatch]);

    return (
        <EcommerceContext.Provider value={{
            steps: state.steps,
            selectedStep: state.selectedStep,
            currentStep: state.currentStep,
            incrementCurrentStep,
            decrementCurrentStep,
            setSteps
        }}>
            {props.children}
        </EcommerceContext.Provider>
    )
}

export default WebState;
import { CheckIcon } from '@heroicons/react/solid'
import { SET_STEPS, INCREMENT_CURRENT_STEP, DECREMENT_CURRENT_STEP } from "../types";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const timelineDesign = [
    {
        id: 1,
        content: 'Te compartimos el documento en línea donde responderas',
        target: 'los aspectos necesarios con el que debe contar tu diseño.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 2,
        content: 'Se programa una reunión',
        target: 'para presentarte la propuesta gráfica.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 3,
        content: 'Se determinan las modificaciones que solicites y',
        target: 'cual es la mejor manera de adaptación.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 4,
        content: 'Se presenta el prototipo final',
        target: 'para su autorización.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 5,
        content: 'Con base a los líneamientos establecidos en reunión',
        target: 'liberamos tu diseño.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
]

const Paso2 = () => {
    return (
<div className="mt-4">
            <div className="flow-root">
                <p className="mt-4 mb-4 text-lg text-gray-500 dark:text-gray-200 text-left">
                    Es fácil, solo compartenos la siguiente información
                </p>
                <div className="p-10">
                    <ul className="-mb-8">
                        {timelineDesign.map((event, eventIdx) => (
                            <li key={event.id}>
                                <div className="relative pb-8">
                                    {eventIdx !== timelineDesign.length - 1 ? (
                                        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                    ) : null}
                                    <div className="relative flex space-x-3">
                                        <div>
                                            <span
                                                className={classNames(
                                                    event.iconBackground,
                                                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-asymmetrics-400'
                                                )}
                                            >
                                                <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                                            </span>
                                        </div>
                                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                            <div>
                                                <p className="text-sm text-gray-500 dark:text-gray-200">
                                                    {event.content}{' '} <strong>{event.target}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const steps = [
    {
        key: 0,
        id: 'Paso 1',
        name: 'Contacto',
        href: '#',
        status: 'current',
        title: 'Contacta con nosotros',
        text: 'Una vez iniciado el contacto, nuestros artísticas escucharan lo que deseas, posteriormente te harán llegar una liga con un documento en línea donde harán una serie de preguntas que debes responder para generar tu propuesta.'
    },
    {
        key: 1,
        id: 'Paso 2',
        name: 'Análisis de información',
        href: '#', status: 'upcoming',
        title: 'Con base al cuestionario que respondiste',
        text: Paso2()
    },
    {
        key: 2,
        id: 'Paso 3',
        name: 'Establecemos metas',
        href: '#',
        status: 'upcoming',
        title: '¿Cuando requieres tu diseño?',
        text: 'Siempre entregamos en tiempo y forma nuestros trabajos, por lo qué, el proyecto se entrega en el tiempo establecido.'
    },
    {
        key: 3,
        id: 'Paso 4',
        name: 'Adelante',
        href: '#',
        status: 'upcoming',
        title: 'Te compartimos todo tu material',
        text: 'Una vez liberado el proyecto, cuenta con todos los productos desarrollados para ti, los cuales te compartiremos digitalmente.'
    }
]

export const defaultStepperState = {
    steps: steps,
    currentStep: 0
};

export const DesignReducer = (state, action) => {
    const { currentStep, steps } = state;
    const { type, payload } = action;
    switch (type) {
        case SET_STEPS:
            return {
                ...state,
                steps: payload.steps
            };
        case INCREMENT_CURRENT_STEP:
            if(currentStep < steps.length - 1){
                var log = currentStep + 1;
                steps.filter(item => item.key === log).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === log - 1).forEach(element => {
                    element.status = 'complete'
                });
                return {
                    ...state,
                    currentStep: currentStep + 1
                }
            }
            else {
                console.log("aqui")
                return {
                    currentStep
                }
            }
        case DECREMENT_CURRENT_STEP:
            console.log(currentStep);
            if (currentStep > 0) {
                var logDecrement = currentStep - 1;
                steps.filter(item => item.key === logDecrement).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === logDecrement + 1).forEach(element => {
                    element.status = 'upcoming'
                });
                return {
                    ...state,
                    currentStep: currentStep - 1
                }
            }
            else {
                return {
                    currentStep
                }
            }
        default:
            return state;
    }
};

export default DesignReducer;
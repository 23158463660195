import { Helmet } from "react-helmet";
import { MailIcon } from '@heroicons/react/solid'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

//Context
import { useContext } from "react";
import SystemsContext from './../context/Systems/SystemsContext'

const profile = {
    name: 'Sistemas',
    email: 'contacto@asymmetrics.com.mx',
    avatar:
        'assets/img/systems/back_asymmetrics_systems.jpg',
    backgroundImage:
        'assets/img/systems/back_asymmetrics_systems.jpg'
}

const handleClickCorreo = () => {
    window.open("mailto:contato@asymmetrics.com.mx", "_blank")
}

const handleClickWhats = () => {
    window.open("https://wa.me/5212227718704?text=Me%20interesa%20un%20Sistema", "_blank")
}

export default function Systems() {
    const { steps, incrementCurrentStep, decrementCurrentStep, currentStep } = useContext(SystemsContext)

    return (
        <>
            <Helmet>
                <title>Sistemas ~ Asymmetrics</title>
                <meta name="description" content="Nos permitimos ofrecerte la mejor solución de sistemas para ti." />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="topBar">
                <div>
                    <img className="h-32 w-full object-cover lg:h-48" src={profile.backgroundImage} alt={profile.name} />
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                        <div className="flex">
                            <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={profile.avatar} alt={profile.name} />
                        </div>
                        <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end text-left sm:space-x-6 sm:pb-1">
                            <div className="sm:hidden md:block mt-6 min-w-0 flex-1">
                                <h1 className="text-2xl font-bold text-gray-900 dark:text-white truncate">Sistemas</h1>
                            </div>
                            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                                <button
                                    onClick={handleClickCorreo}
                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >
                                    <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span>Correo electrónico</span>
                                </button>
                                <button
                                    onClick={handleClickWhats}
                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >
                                    <WhatsAppIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span>WhatsApp</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
                        <h1 className="text-2xl font-bold text-gray-900 dark:text-white truncate">Sistemas</h1>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-12">
                    <div className="md:col-span-2 shadow-lg dark:shadow-dark rounded-lg p-4 md:p-10">
                        <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 dark:text-white sm:text-4xl text-left w-100">
                            <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-white bg-clip-text text-transparent">
                                ¿Necesito un sistema informático
                            </span>
                            ?
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 text-left">
                            En algún momento, las empresas se encuentran con muchos retos, entre ellos, el de tener el control de sus procesos, si en cuanto más crece tu empresa, el control se vuelve más complejo, muy posiblemente necesites un sistema, tal cómo, punto de venta, CRM, newsletter, etc.
                        </p>
                    </div>
                    <div className="shadow-lg dark:shadow-dark rounded-lg p-4 md:p-10">
                        <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 dark:text-white sm:text-4xl text-left">
                            <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-white bg-clip-text text-transparent">
                                ¿Quiénes lo hacen
                            </span>
                            ?
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 dark:text-gray-300  text-left">
                            Nuestro equipo de ingenieros e ingenieras altamente capacitados estarán contigo mano a mano.
                        </p>
                    </div>
                </div>
                <div className="max-w-7xl mx auto shadow-lg dark:shadow-dark rounded-lg">
                    <div className="p-4 md:p-10">
                        <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 dark:text-white sm:text-4xl text-left w-100">
                            <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-white bg-clip-text text-transparent">
                                Implementaciones
                            </span>
                        </h2>
                        <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 text-left">
                            Es necesario contar con la infraestructura y tecnología necesaría para crecer nuestras empresas, es por ello, que estamos preparados para ayudarte a implementar las soluciones de alta tecnología que ofrecen distintos proveedores.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="p-4 md:p-10 mx-auto flex">
                            <img src="assets/img/systems/aws.png" alt="AWS Logo" className="w-1/2 mx-auto self-center dark:hidden" />
                            <img src="assets/img/systems/aws-dark.jpg" alt="AWS Logo" className="w-3/4 mx-auto self-center hidden dark:block" />
                        </div>
                        <div className="col-span-2 p-4 md:p-10">
                            <h1 className="text-2xl font-bold text-gray-900 dark:text-white truncate">Amazon Web Services</h1>
                            <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 text-left">
                                Amazon se ha convertido en una de las empresas líderes de las tecnologías de la información, nuestros ingenieros te ayudarán a implementar soluciones de AWS tales cómo: <br /><br />
                                <ul>
                                    <li>Lightsail</li>
                                    <li>EC2</li>
                                    <li>Bases de datos</li>
                                    <li>Amazon Lambda</li>
                                    <li>Amazon API Gateway</li>
                                    <li>Entre otros...</li>
                                </ul>
                            </p>
                        </div>
                        <div className="p-4 md:p-10 mx-auto flex">
                            <img src="assets/img/systems/azure.png" alt="AWS Logo" className="w-3/4 mx-auto self-center" />
                        </div>
                        <div className="col-span-2 p-4 md:p-10">
                            <h1 className="text-2xl font-bold text-gray-900 dark:text-white truncate">Microsoft Azure</h1>
                            <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 text-left">
                                Las soluciones que ofrece Azure son bastante amplias, por lo que estamos preparados para implementarlas, muchos clientes se encuentran satisfechos con sus herramientas. <br /><br />
                                <ul>
                                    <li>Chatbot</li>
                                    <li>Azure Cognitive Services</li>
                                    <li>Azure Search</li>
                                    <li>SQL Database</li>
                                    <li>Azure Active Directory</li>
                                    <li>Entre otros...</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="shadow-lg dark:shadow-dark rounded-lg p-4 md:p-10 mt-10">
                    <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 dark:text-white sm:text-4xl text-left">
                        <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-white bg-clip-text text-transparent">
                            ¿Cómo iniciar
                        </span>
                        ?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 text-left">
                        Nuestro equipo de ingenieros e ingenieras altamente capacitados estarán contigo mano a mano.
                    </p>
                    <div className="mt-12 text-left">
                        <nav aria-label="Progress">
                            <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
                                {steps.length ? steps.map((step) => (
                                    <li key={step.name} className="md:flex-1">
                                        {step.status === 'complete' ? (
                                            <a
                                                href={step.href}
                                                className="group pl-4 py-2 flex flex-col border-l-4 dark:border-yellow-secondary-300 border-yellow-secondary-600 hover:border-yellow-secondary-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                            >
                                                <span className="text-xs text-asymmetrics-600 dark:text-gray-200 font-semibold tracking-wide uppercase group-hover:text-asymmetrics-800 dark:group-hover:text-asymmetrics-100">
                                                    {step.id}
                                                </span>
                                                <span className="text-sm font-medium dark:text-white">{step.name}</span>
                                            </a>
                                        ) : step.status === 'current' ? (
                                            <a
                                                href={step.href}
                                                className="pl-4 py-2 flex flex-col border-l-4 border-asymmetrics-600 dark:border-yellow-500 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                                aria-current="step"
                                            >
                                                <span className="text-xs text-asymmetrics-600 dark:text-white font-semibold tracking-wide uppercase">{step.id}</span>
                                                <span className="text-sm font-medium dark:text-gray-300">{step.name}</span>
                                            </a>
                                        ) : (
                                            <a
                                                href={step.href}
                                                className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                                            >
                                                <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700 dark:group-hover:text-gray-300">
                                                    {step.id}
                                                </span>
                                                <span className="text-sm font-medium dark:text-gray-500">{step.name}</span>
                                            </a>
                                        )}
                                    </li>
                                )) : null}
                            </ol>
                        </nav>
                        <div className="pt-10">
                            <div className="text-center md:text-right md:pt-10 pb-10">
                                {
                                    currentStep === 0 ?
                                        <button
                                            type="button"
                                            className="disabled ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                        >
                                            Anterior
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            onClick={decrementCurrentStep}
                                            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-600 hover:bg-asymmetrics-600 dark:hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                        >
                                            Anterior
                                        </button>
                                }
                                {
                                    currentStep > 2 ?
                                        <button
                                            type="button"
                                            className="disabled ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                        >
                                            Listo
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            onClick={incrementCurrentStep}
                                            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-600 hover:bg-asymmetrics-600 dark:hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                                        >
                                            Siguiente
                                        </button>
                                }
                            </div>
                            {
                                steps.filter(item => item.key === currentStep).map((element) => (
                                    <div>
                                        <h1 className="text-2xl font-bold leading-tight text-asymmetrics-700 dark:text-gray-200">{element.title}</h1>
                                        <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 text-left">
                                            {element.text}
                                        </p>
                                    </div>
                                )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10 mb-10">
                <div className="p-4 md:p-10 rounded-lg shadow-lg dark:shadow-dark">
                    <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 dark:text-white sm:text-4xl text-left w-100">
                        <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-white bg-clip-text text-transparent">
                            ¿Cuanto costará mi proyecto
                        </span>
                        ?
                    </h2>
                    <p className="mt-4 text-lg text-gray-500 dark:text-gray-300 text-left">
                        El costo de un proyecto puede tornarse bastante relativo, por favor ponte en contacto con nosotros para realizar la cotización.
                    </p>
                    <br />
                    <button
                        type={handleClickWhats}
                        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-secondary-600 hover:bg-asymmetrics-600 dark:hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-asymmetrics-500"
                    >
                        Contactar
                    </button>
                </div>
            </div>
        </>
    );
}
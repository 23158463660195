import { useReducer, useCallback } from "react";
import { AppsReducer, defaultStepperState } from "./AppsReducer";
import AppsContext from "./AppsContext"

const AppsState = (props) => {
    
    const [state, dispatch] = useReducer(AppsReducer, defaultStepperState)

    const incrementCurrentStep = useCallback(() => {
        dispatch({
            type: 'INCREMENT_CURRENT_STEP'
        });
    }, [dispatch]);

    const decrementCurrentStep = useCallback(() => {
        dispatch({
            type: 'DECREMENT_CURRENT_STEP'
        });
    }, [dispatch]);

    const setSteps = useCallback(steps => dispatch({ type: 'SET_STEPS', payload: { steps } }), [dispatch]);

    return (
        <AppsContext.Provider value={{
            steps: state.steps,
            selectedStep: state.selectedStep,
            currentStep: state.currentStep,
            incrementCurrentStep,
            decrementCurrentStep,
            setSteps
        }}>
            {props.children}
        </AppsContext.Provider>
    )
}

export default AppsState;
import { CheckIcon } from '@heroicons/react/solid'
import { SET_STEPS, INCREMENT_CURRENT_STEP, DECREMENT_CURRENT_STEP } from "../types";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const timelineWeb = [
    {
        id: 1,
        content: 'Un documento donde se encuentren',
        target: 'los textos dividos por pestañas.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 2,
        content: 'Tu material gráfico cómo lo es',
        target: 'tu isotipo, logotipo, variantes, etc.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 3,
        content: 'Tu contenido múltimedia, videos, fotos',
        target: 'y el material visual que se integrará a tu página.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 4,
        content: 'Tu nombre de dominio y ',
        target: 'fecha de publicación.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
    {
        id: 5,
        content: 'Procedemos a la liberación en tiempo',
        target: 'y forma.',
        icon: CheckIcon,
        iconBackground: 'bg-green-500',
    },
]

const Paso2 = () => {
    return (
        <div className="mt-4">
            <div className="flow-root">
                <p className="mt-4 mb-4 text-lg text-gray-500 dark:text-gray-300 text-left">
                    Es fácil, solo compartenos la siguiente información
                </p>
                <div className="p-10">
                    <ul className="-mb-8">
                        {timelineWeb.map((event, eventIdx) => (
                            <li key={event.id}>
                                <div className="relative pb-8">
                                    {eventIdx !== timelineWeb.length - 1 ? (
                                        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                    ) : null}
                                    <div className="relative flex space-x-3">
                                        <div>
                                            <span
                                                className={classNames(
                                                    event.iconBackground,
                                                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-asymmetrics-400'
                                                )}
                                            >
                                                <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                                            </span>
                                        </div>
                                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                            <div>
                                                <p className="text-sm text-gray-500 dark:text-gray-300">
                                                    {event.content}{' '} <strong>{event.target}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const steps = [
    {
        key: 0,
        id: 'Paso 1',
        name: 'Contacto',
        href: '#',
        status: 'current',
        title: 'Contacta con nosotros',
        text: 'A través de una breve llamada de unos 20 minutos, cuéntanos cuál es tu proyecto o que tienes en mente y con base en ello te proponemos la solución que mejor se adapte a tu negocio. Sugerimos tener en mente tiempo máximo de desarrollo, funcionalidades especificas en caso de que existan, número de pestañas y si cuentan con imagen para su website.'
    },
    {
        key: 1,
        id: 'Paso 2',
        name: 'Compartenos tu información',
        href: '#', status: 'upcoming',
        title: 'Envíanos tu información',
        text: Paso2()
    },
    {
        key: 2,
        id: 'Paso 3',
        name: 'Establecemos metas',
        href: '#',
        status: 'upcoming',
        title: '¿Cuando requieres que este en línea?',
        text: 'El tiempo medio que utilizamos para construir tu sitio web es de 3 a 4 semanas, por lo que, si se adapta a tu meta, con gusto iniciamos, si necesitas menos tiempo, ¡también lo logramos!'
    },
    {
        key: 3,
        id: 'Paso 4',
        name: 'Adelante',
        href: '#',
        status: 'upcoming',
        title: 'Ya establecimos metas y conceptos',
        text: 'Si te parece bien, nos reunimos una vez a la semana para realizar avances y recibir retroalimentación por tu parte, no liberamos el sitio web hasta que estes 100% satisfecho.'
    }
]

export const defaultStepperState = {
    steps: steps,
    currentStep: 0
};

export const WebReducer = (state, action) => {
    const { currentStep, steps } = state;
    const { type, payload } = action;
    switch (type) {
        case SET_STEPS:
            return {
                ...state,
                steps: payload.steps
            };
        case INCREMENT_CURRENT_STEP:
            if(currentStep < steps.length - 1){
                var log = currentStep + 1;
                steps.filter(item => item.key === log).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === log - 1).forEach(element => {
                    element.status = 'complete'
                });
                return {
                    ...state,
                    currentStep: currentStep + 1
                }
            }
            else {
                console.log("aqui")
                return {
                    currentStep
                }
            }
        case DECREMENT_CURRENT_STEP:
            console.log(currentStep);
            if (currentStep > 0) {
                var logDecrement = currentStep - 1;
                steps.filter(item => item.key === logDecrement).forEach(element => {
                    element.status = 'current'
                });
                steps.filter(item => item.key === logDecrement + 1).forEach(element => {
                    element.status = 'upcoming'
                });
                return {
                    ...state,
                    currentStep: currentStep - 1
                }
            }
            else {
                return {
                    currentStep
                }
            }
        default:
            return state;
    }
};

export default WebReducer;
import { Helmet } from "react-helmet";
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

const handleClickWhats = () => {
    window.open("https://wa.me/5212227718704?text=Me%20interesa%20la%20Responsabilidad%20Social", "_blank")
}

const handleClickWhatsSustentabilidad = () => {
    window.open("https://wa.me/5212227718704?text=Me%20interesa%20ser%20Sustentable", "_blank")
}

export default function Us() {
    return (
        <>
            <Helmet>
                <title>Nosotros ~ Asymmetrics</title>
                <meta name="description" content="Nuestro equipo de artísticas plasmará tus ideas a nivel dígital." />
                <meta name="robots" content="index, follow" />

                <meta property="og:url" content="https://asymmetrics.com.mx/us" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Asymmetrics ~ Nosotros" />
                <meta property="og:description" content="En Asymmetrics creemos en un mejor futuro para México, nuestra filosofía es apoyar al impulso de todo tipo de empresa o persona para su propio crecimiento." />
                <meta property="og:image" content="https://asymmetrics.vercel.app/assets/img/FBOG/Asymmetrics-Facebook-OG-Nosotros.png" />
            </Helmet>
            <div className="topBar">
                <div className="lg:relative">
                    <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
                        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white  sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                                <span className="block xl:inline">Te compartimos acerca de </span>{' '}
                                <span className="block text-asymmetrics-500 dark:text-asymmetrics-300 xl:inline">nuestra filosofia</span>
                            </h1>
                            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 dark:text-gray-400 sm:text-xl md:mt-5 md:max-w-3xl">
                                En Asymmetrics creemos en un mejor futuro para México, nuestra filosofía es apoyar al impulso de todo tipo de empresa o persona para su propio crecimiento, no dejando atras <span className="font-bold"> la responsabilidad social y la
                                    sustentabilidad.</span>
                            </p>
                            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                                <div className="rounded-md shadow">
                                    <a
                                        href="/"
                                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-asymmetrics-500 hover:bg-asymmetrics-700 md:py-4 md:text-lg md:px-10"
                                    >
                                        ¿Quiéres saber sí compartimos nuestra filosofía?
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                        <img
                            className="absolute inset-0 w-full h-full object-cover"
                            src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                            alt=""
                        />
                    </div>
                </div>
                <div className="max-w-7xl mx-auto">
                    <div class="grid grid-cols-2 gap-6 p-10">
                        <div>
                            <img src="assets/img/nosotros/ResponsabilidadSocial.jpg" alt="Responsabilidad Social" className="mx-auto rounded-lg w-3/4 shadow-lg" />
                        </div>
                        <div className="flex">
                            <div className="self-center">
                                <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-left w-100">
                                    <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-asymmetrics-300 bg-clip-text text-transparent">
                                        Responsabilidad social
                                    </span>
                                </h2>
                                <p className="mt-4 text-lg text-gray-500 dark:text-gray-400 text-left">
                                    Creemos que cómo seres humanos tenemos la responsabildad de aportar a la sociedad, trabajamos constantemente en generar modelos de negocios que aporten a los sectores vulnerables de México y el mundo. <br /><br />
                                    Te sugerimos y proponemos que si eres freelancer, startup o empresa, consideres modificar tu modelo de negocios para aportar un granito de arena. <br /><br />
                                    Si deseas integrarte a esta causa y no sabes como, ponte en contacto con nosotros y con mucho gusto te apoyamos.
                                </p>
                                <button
                                    onClick={handleClickWhats}
                                    className="mt-4 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >
                                    <WhatsAppIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span>Quiero ser socialmente responsable</span>
                                </button>
                            </div>
                        </div>
                        <div>
                            <img src="assets/img/nosotros/Sustentabilidad.png" alt="Sustentabilidad" className="mx-auto rounded-lg w-3/4 shadow-lg" />
                        </div>
                        <div className="flex">
                            <div className="self-center">
                                <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-left w-100">
                                    <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-asymmetrics-300 bg-clip-text text-transparent">
                                        Sustentabilidad
                                    </span>
                                </h2>
                                <p className="mt-4 text-lg text-gray-500 dark:text-gray-400 text-left">
                                    La tecnología y el ambiente no están peleados. Nuestro sueño es que en Asymmetrics, todos los equipos de cómputo, servidores y cualquier electrónico este alimentado con energía solar. <br /><br />
                                    Intentamos también, consumir los menos recursos posibles para combatir el cambio climático. <br /><br />
                                    Invertiremos en tecnología sustentable para la utilización de los demás.
                                </p>
                                <button
                                    onClick={handleClickWhatsSustentabilidad}
                                    className="mt-4 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >
                                    <WhatsAppIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span>Quiero ser sustentable</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-asymmetrics-600">
                        <div className="flex min-h-full">
                            <div className="self-center justify-center w-full container mx-auto">
                                <div className="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                                    <h2 className="text-4xl font-extrabold tracking-tight text-asymmetrics-600 sm:text-4xl text-center pb-10 w-100">
                                        <span className="bg-gradient-to-r from-yellow-secondary-600 to-asymmetrics-600 dark:to-asymmetrics-300 bg-clip-text text-transparent">
                                            Nuestros clientes más recientes
                                        </span>
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500 dark:text-gray-400 text-center">
                                        Agradecemos la confianza y el apoyo, siempre listos para seguir brindando soluciones con la mejor calidad de servicio.
                                    </p>
                                    <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                            <img
                                                className="max-h-12"
                                                src="assets/img/logos/LogoBruck.png"
                                                alt="Logo Brück Germany"
                                            />
                                        </div>
                                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                            <img
                                                className="max-h-12"
                                                src="assets/img/logos/LogoLightBriomant.png"
                                                alt="Logo Briomant" />
                                        </div>
                                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                            <img
                                                className="max-h-12"
                                                src="assets/img/logos/LogoVanessaCampos.png"
                                                alt="Logo Vanessa Campos" />
                                        </div>
                                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                            <img
                                                className="max-h-12"
                                                src="assets/img/logos/IntexLogoWeb.png"
                                                alt="Logo IntexTrading" />
                                        </div>
                                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                            <img
                                                className="max-h-20"
                                                src="assets/img/logos/LogoYachtsAndFishin.png"
                                                alt="Logo Yachts and Fishin"
                                            />
                                        </div>
                                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                            <img
                                                className="max-h-10"
                                                src="assets/img/logos/OxiPue_Logotipo.png"
                                                alt="Logo OxiPue"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


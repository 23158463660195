import { useReducer, useCallback } from "react";
import { LandingPageReducer, defaultStepperState } from "./LandingPageReducer";
import LandingPageContext from "./LandingPageContext"

const LandingPageState = (props) => {
    
    const [state, dispatch] = useReducer(LandingPageReducer, defaultStepperState)

    console.log(state)

    const incrementCurrentStep = useCallback(() => {
        dispatch({
            type: 'INCREMENT_CURRENT_STEP'
        });
    }, [dispatch]);

    const decrementCurrentStep = useCallback(() => {
        dispatch({
            type: 'DECREMENT_CURRENT_STEP'
        });
    }, [dispatch]);

    const setSteps = useCallback(steps => dispatch({ type: 'SET_STEPS', payload: { steps } }), [dispatch]);

    return (
        <LandingPageContext.Provider value={{
            steps: state.steps,
            selectedStep: state.selectedStep,
            currentStep: state.currentStep,
            incrementCurrentStep,
            decrementCurrentStep,
            setSteps
        }}>
            {props.children}
        </LandingPageContext.Provider>
    )
}

export default LandingPageState;
import './App.css';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import WebState from './context/Web/WebState'
import DesignState from './context/Design/DesignState'
import SystemsState from './context/Systems/SystemsState'
import EcommerceState from './context/Ecommerce/EcommerceState'
import AppsState from './context/Apps/AppsState'
import LandingPageState from './context/LandingPage/LandingPageState'
import CMSState from './context/CMS/CMSState'
import SMMState from './context/SMM/SMMState'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// View
import WebDeveloper from './pages/WebDeveloper'
import Inicio from './pages/Inicio'
import Design from './pages/Design'
import Systems from './pages/Systems'
import Ecommerce from './pages/Ecommerce'
import Apps from './pages/Apps'
import LandingPage from './pages/LandingPage'
import CMS from './pages/CMS'
import SMM from './pages/SMM'
import Us from './pages/Us'
import Customers from './pages/Customers'
import Contact from './pages/Contact'

function App() {
  return (
    <Router>
      <div className="App dark:bg-asymmetrics-600">
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Inicio />
          </Route>
          <Route exact path="/web">
            <WebState>
              <WebDeveloper />
            </WebState>
          </Route>
          <Route exact path="/design">
            <DesignState>
              <Design />
            </DesignState>
          </Route>
          <Route exact path="/systems">
            <SystemsState>
              <Systems />
            </SystemsState>
          </Route>
          <Route exact path="/ecommerce">
            <EcommerceState>
              <Ecommerce />
            </EcommerceState>
          </Route>
          <Route exact path="/apps">
            <AppsState>
              <Apps />
            </AppsState>
          </Route>
          <Route exact path="/landingpage">
            <LandingPageState>
              <LandingPage />
            </LandingPageState>
          </Route>
          <Route exact path="/cms">
            <CMSState>
              <CMS />
            </CMSState>
          </Route>
          <Route exact path="/smm">
            <SMMState>
              <SMM />
            </SMMState>
          </Route>
          <Route exact path="/us">
              <Us />
          </Route>
          <Route exact path="/customers">
              <Customers />
          </Route>
          <Route exact path="/contact">
              <Contact />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
